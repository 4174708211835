.App {
    text-align: center;
}

.app, app-dashboard, app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;
}

.shadow-md {
    box-shadow: 0 .025rem .25rem rgba(0,0,0,.1) !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.border-0 {
    border: 0 !important;
}

.bg-light {
    background-color: #f0f3f5 !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #c2cfd6;
}

.card {
    border-color: #fff;
    border-radius: 10px;
}

.body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #151b1e;
    text-align: left;
    background-color: #fff;
}

.ver {
    align-self: end;
    padding: 20px;
}
